<template>
  <v-card class="pa-0 mt-3" flat>
    <v-row class="mt-3 mb-2 mx-0">
      <v-col cols="12" class="pa-0 pl-1 pt-1">
        <v-autocomplete outlined dense :label="$t('message.projects.project')" :items="listOfProjects" hide-details v-model="projectId" @change="getModuleReport()" item-text="projectName" item-value="id"></v-autocomplete>
      </v-col>
    </v-row>
    <v-skeleton-loader class="mx-auto" v-if="chartLoading" type="table-heading, list-item-two-line,  list-item-two-line,  list-item-two-line, list-item-two-line"></v-skeleton-loader>
    <template v-else>
      <!-- <v-card-title class="py-0 px-1">{{ projectName }}</v-card-title> -->
      <highcharts :options="chartOptions" ref="barChart"></highcharts>
    </template>
  </v-card>
</template>

<script>
import { hostAppApi } from '../../plugins/axios_settings'
import { mapGetters } from 'vuex'
import projectMixin from '@/mixins/projectMixin'
export default {
  mixins: [projectMixin],
  data () {
    return {
      projectId: parseInt(this.$route.params.project_id),
      projectName: '',
      listOfProjects: [],
      chartLoading: false,
      chartOptions: {
        chart: {
          type: 'column',
          height: 450
        },
        title: {
          text: this.$t('message.moduleReports.title')
        },
        credits: false,
        xAxis: {
          title: {
            text: this.$t('message.customModule.modules')
          },
          categories: [],
          crosshair: true
        },
        tooltip: {
          formatter: function () {
            return '' + this.series.name + ': ' + this.y + ' records'
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: this.$t('message.moduleReports.chartyAxis')
          }
        },
        plotOptions: {
          column: {
            /* stacking: 'normal', */
            dataLabels: {
              enabled: true
            },
            pointPadding: 0.2,
            borderWidth: 0
          }
        },
        series: []
      },
      role: this.$formatter.cloneVariable(this.$store.state.auth.role)
    }
  },
  created () {
    this.getAllProjects()
    this.getModuleReport()
    this.$eventBus.$on('languageChanged', (locale) => {
      this.getModuleReport()
    })
  },
  computed: {
    ...mapGetters(['getHostRefApi', 'userId'])
  },
  methods: {
    isAdmin () {
      return ['admin', 'superadmin'].includes(this.role.slug)
    },
    getAllProjects () {
      this.getSetListItems(`projects/get_as_simplified_for_pwa?filter=${this.isAdmin() ? 4 : 2}`, 'listOfProjects') // 4 is all, 2 is myall
    },
    getModuleReport () {
      this.chartLoading = true
      hostAppApi.get(`${this.getHostRefApi}get_project_modules_report?project_id=${this.projectId}&user_id=${this.userId}`, { headers: { language: this.$i18n.locale } })
        .then((response) => {
          if (response.data) {
            const { categories, reportdata } = response.data // , project
            this.chartOptions.xAxis.categories = categories
            this.chartOptions.series = reportdata
            // this.projectName = `${project.po_ranked ? `${project.po_ranked} -` : ''} ${project.project_name ? `${project.project_name} -` : ''} ${project.ao_ranked ? `${project.ao_ranked}` : ''}`
          }
        })
        .finally(() => {
          this.chartLoading = false
        })
    },
    getSetListItems (url, listName) {
      this.$api.execute('get', url).then((response) => {
        if (response.data) {
          if (listName === 'listOfProjects') {
            const list = this.$formatter.cloneVariable(response.data)
            const timerProject = list.find(x => x.number === 'TM001')
            if (timerProject) {
              const indexOf = list.indexOf(timerProject)
              list.splice(indexOf, 1)
            }
            list.forEach(project => {
              project.projectName = this.setProjectname(project)
            })
            this[listName] = list
          } else this[listName] = response.data
        }
      })
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('languageChanged')
  }
}
</script>

<style lang="scss" scoped>

</style>
